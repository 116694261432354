import {
    Box,
    Drawer,
    DrawerContent,
    useDisclosure,
    Text,
    DrawerOverlay,
    DrawerCloseButton, DrawerBody
} from '@chakra-ui/react'

import { API_ENDPOINT } from 'config/constants'
import useFetch from 'hooks/useFetch'

import Sidebar from './components/Sidebar'
import MobileNavigation from './components/MobileNavigation'
import KycContainer from './components/KycContainer'

const Kyc = ({ status }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { data: kycInformation, additionalData, isLoading, error, fetchData: reloadData } = useFetch(API_ENDPOINT + '/list-kyc?status=' + status);

    return (
        <Box minH="100vh">
            <Sidebar
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
            />

            <Drawer
                placement="left"
                autoFocus={false}
                isOpen={isOpen}
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton color="white" />

                        <DrawerBody>
                            <Sidebar isOpen={isOpen} onClose={onClose} />
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>

            <MobileNavigation onOpen={onOpen} stats={additionalData} />

            <Box ml={{ base: 0, md: 60 }} p={6}>
                {isLoading ? (
                    <Text color="content">Fetching KYC information...</Text>
                ) : error ? (
                    <Text color="content">Unable to fetch KYC information - {error.message && error.message} {error.code && '(' + error.code + ')'}</Text>
                ) : (
                    <KycContainer information={kycInformation} data={additionalData} status={status} reloadData={reloadData} />
                )}
            </Box>
        </Box>
    );
}

export default Kyc;