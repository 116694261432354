import { Image } from '@chakra-ui/react'

const FullscreenImage =  ({ ...rest }) => {
    const displayFullscreen = event => {
        const element = event.target;

        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        }
    }

    return (
        <Image
            onClick={e => displayFullscreen(e)}
            _hover={{
                cursor: 'pointer'
            }}
            {...rest}
        />
    );
}

export default FullscreenImage;