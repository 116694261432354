import { API_ENDPOINT } from 'config/constants'

import { Table, TableContainer, TableCaption, Flex, Text, Thead, Tr, Th, Tbody, Button } from '@chakra-ui/react'
import KycItem from './KycItem'

const KycContainer = ({ information, data, status, reloadData }) => {
    const fetchNewData = pageNumber => {
        reloadData(API_ENDPOINT + '/list-kyc?status=' + status + '&page=' + pageNumber, new AbortController().signal);
    }

    return (
        <TableContainer>
            <Table variant="simple">
                <TableCaption color="content">
                    <Flex justifyContent="space-between" alignItems="center">
                        <Button colorScheme="contentButton" color="primary" onClick={() => fetchNewData(parseInt(data.page) - 1)} disabled={data.page <= 1}>&lt; Previous page</Button>
                        <Button colorScheme="contentButton" color="primary" onClick={() => fetchNewData(parseInt(data.page))}>Refresh</Button>
                        <Button colorScheme="contentButton" color="primary" onClick={() => fetchNewData(parseInt(data.page) + 1)} disabled={data.page >= data.totalPage}>Next page &gt;</Button>
                    </Flex>
                </TableCaption>

                <TableCaption color="content"><Text>Page {data.page} of {data.totalPage ? data.totalPage : 1}</Text></TableCaption>
                <TableCaption color="content">Merkle Root: {data.merkleRoot}</TableCaption>
                <TableCaption color="content">Whitelist Merkle Root: {data.wlMerkleRoot}</TableCaption>

                <Thead>
                    <Tr>
                        <Th color="content">First name</Th>
                        <Th color="content">Last name</Th>
                        <Th color="content">Country</Th>
                        <Th color="content">Exp. date</Th>
                        <Th color="content">CNI recto</Th>
                        <Th color="content">CNI verso</Th>
                        <Th color="content">Address</Th>
                        {status !== 'approved' && <Th isNumeric={true} color="content">Actions</Th>}
                    </Tr>
                </Thead>

                <Tbody>
                    {information.map(item => (
                        <KycItem key={item.id} data={item} status={status} />
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
}

export default KycContainer;