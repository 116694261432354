export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const TOAST_DEFAULT_STYLE = {
    position: 'top-right',
    variant: 'left-accent',
    duration: process.env.REACT_APP_TOAST_DURATION,
    isClosable: true
};

export const KYC_STATUS_DATA = {
    approve: {
        uri: 'approve-kyc',
        toastTitle: 'Approved',
        toastStatus: 'success'
    },
    reject: {
        uri: 'reject-kyc',
        toastTitle: 'Rejected',
        toastStatus: 'success'
    }
};