import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

import { useAuthentication } from 'context/AuthProvider'

const useFetch = url => {
    const authentication = useAuthentication();

    const [results, setResults] = useState([]);
    const [additionalData, setAdditionalData] = useState({
        page: 1,
        totalPage: 1,
        total: 0,
        pending: 0,
        approved: 0,
        merkleRoot: null,
        wlMerkleRoot: null
    });

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async (_url, signal) => {
        try {
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + authentication.accessToken
                },
                signal
            };

            const response = await axios.get(_url, config);
            const data = response.data;

            if (data) {
                setResults(data.results);
                setAdditionalData({
                    page: data.page,
                    totalPage: data.total_page,
                    total: data.total,
                    pending: data.pending,
                    approved: data.approved,
                    merkleRoot: data.merkle_root,
                    wlMerkleRoot: data.wl_merkle_root
                });
            }
        } catch (err) {
            if (!signal.aborted) {
                setError({
                    message: err.response?.data || 'Unknown error',
                    code: err.code
                });
            }
        } finally {
            if (!signal.aborted) setIsLoading(false);
        }
    }, [authentication.accessToken]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetchData(url, signal).then();

        return () => controller.abort();
    }, [url, fetchData]);

    return { data: results, additionalData, isLoading, error, fetchData };
}

export default useFetch;