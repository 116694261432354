import { useState } from 'react'

const useLocalStorage = (keyName, defaultValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = localStorage.getItem(keyName);
            return value ? value : defaultValue;
        } catch (err) {
            return defaultValue;
        }
    });

    const setValue = newValue => {
        localStorage.setItem(keyName, newValue);
        setStoredValue(newValue);
    };

    const removeValue = () => {
        localStorage.removeItem(keyName);
        setStoredValue(null);
    }

    return [storedValue, setValue, removeValue];
};

export default useLocalStorage;