import { createContext, useCallback, useContext, useMemo } from 'react'
import axios from 'axios'
import { useToast } from '@chakra-ui/react'
import { useJwt } from 'react-jwt'

import useLocalStorage from 'hooks/useLocalStorage'
import { API_ENDPOINT, TOAST_DEFAULT_STYLE } from 'config/constants'

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const storageKey = process.env.REACT_APP_STORAGE_KEY;

    const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage(storageKey, null);
    const { decodedToken, isExpired } = useJwt(accessToken);
    const toast = useToast();

    const handleLogin = useCallback((login, password) => {
        const url = API_ENDPOINT + '/login';
        axios
            .post(url, { login, password })
            .then(response => {
                toast({
                    title: 'Success',
                    description: 'Redirecting...',
                    status: 'success',
                    ...TOAST_DEFAULT_STYLE
                });

                setTimeout(() => setAccessToken(response.data.accessToken), 2000);
            })
            .catch(err => {
                toast({
                    title: 'Login error',
                    description: err.response?.data?.message || 'Unknown error',
                    status: 'error',
                    ...TOAST_DEFAULT_STYLE
                });
            });
    }, [toast, setAccessToken]);

    const handleLogout = useCallback(() => {
        removeAccessToken();
    }, [removeAccessToken]);

    const value = useMemo(
        () => ({
            accessToken,
            decodedToken,
            isExpired,
            handleLogin,
            handleLogout
        }),
        [accessToken, decodedToken, isExpired, handleLogin, handleLogout]
    );

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuthentication = () => {
    return useContext(AuthContext);
};