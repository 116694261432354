import { useAuthentication } from 'context/AuthProvider'
import { Navigate } from 'react-router-dom'

const AuthenticatedRoute = ({ children }) => {
    const { accessToken, isExpired } = useAuthentication();

    if (accessToken && !isExpired) {
        return <Navigate to="/kyc/pending" />;
    }

    return children;
}

export default AuthenticatedRoute;