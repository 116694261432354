import { NavLink as RouteLink } from 'react-router-dom'
import { Flex, Icon, Link } from '@chakra-ui/react'

const NavigationItem = ({ icon, to, children, ...rest }) => {
    return (
        <Link as={RouteLink} to={to} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} _activeLink={{
            '& > #router-link': {
                bg: 'content',
                color: 'primary'
            }
        }}>
            <Flex
                id="router-link"
                align="center"
                p={4}
                mx={4}
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color="content"
                _hover={{
                    bg: 'content',
                    color: 'primary'
                }}
                {...rest}
            >
                {icon && (
                    <Icon
                        mr={4}
                        fontSize={16}
                        _groupHover={{
                            color: 'primary'
                        }}
                        as={icon}
                    />
                )}

                {children}
            </Flex>
        </Link>
    );
}

export default NavigationItem;