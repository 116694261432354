import { Flex, Stack, Box, Image } from '@chakra-ui/react'
import FormContent from './components/FormContent'
import { useAuthentication } from 'context/AuthProvider'

const Login = () => {
    const { handleLogin } = useAuthentication();

    return (
        <Flex
            flexDirection="column"
            width="100wh"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
                spacing={5}
            >
                <Image src={process.env.PUBLIC_URL + '/images/logo.png'} w={{ base: '80%', md: '400px' }} alt="W.A.G.T.I Logo" />
                <Box minW={{ base: '90%', md: '468px' }} borderRadius="lg">
                    <FormContent onLogin={handleLogin} />
                </Box>
            </Stack>
        </Flex>
    );
}

export default Login;