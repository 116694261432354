import { useNavigate } from 'react-router-dom'
import { Flex, Image, Stack, Text, Link, Icon } from '@chakra-ui/react'
import { FiArrowLeftCircle } from 'react-icons/fi'

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <Flex
            flexDirection="column"
            width="100wh"
            height="100vh"
            justifyContent="center"
            alignItems="center"
        >
            <Stack
                flexDir="column"
                mb="2"
                justifyContent="center"
                alignItems="center"
                spacing={5}
            >
                <Image src={process.env.PUBLIC_URL + '/images/logo.png'} w="400px" alt="W.A.G.T.I Logo" />
                <Text color="content" fontSize="3xl">Page Not Found</Text>

                <Link color="content" onClick={() => navigate(-1)}>
                    <Icon as={FiArrowLeftCircle} mr={2} />
                    Go Back
                </Link>
            </Stack>
        </Flex>
    );
}

export default NotFound;