import { useState } from 'react'
import useAction from 'hooks/useAction'

import { Tr, Td, Button, Flex, Text, Tooltip } from '@chakra-ui/react'
import FullscreenImage from 'components/FullscreenImage'
import ActionText from './ActionText'

const KycItem = ({ data, status }) => {
    const action = useAction();
    const [actionRealised, setActionRealised] = useState(null);

    const onClickAddress = address => {
        navigator.clipboard.writeText(address).then(() => {
            // toast, icon ?
        }, err => {
            console.error('Unable to copy selected address:', err);
        });
    }

    const formatAddress = address => {
        return address.substring(0, 3) + '...' + address.slice(-3);
    }

    const sendRequest = (actionName, kycId) => {
        action(actionName, kycId);
        setActionRealised(actionName);
    }

    return (
        <Tr>
            <Td color="content">{data.firstname}</Td>
            <Td color="content">{data.lastname}</Td>
            <Td color="content">{data.country}</Td>
            <Td color="content">{data.exp_date}</Td>

            <Td color="content"><FullscreenImage src={data.front_id} w={100} /></Td>
            <Td color="content"><FullscreenImage src={data.back_id} w={100} /></Td>

            <Td color="content">
                <Tooltip label="Copy">
                    <Text _hover={{ color: 'hoverContent', cursor: 'pointer' }} onClick={() => onClickAddress(data.address)}>{formatAddress(data.address)}</Text>
                </Tooltip>
            </Td>

            {status !== 'approved' &&
                <Td isNumeric={true} color="content">
                    {actionRealised !== null ?
                        <ActionText action={actionRealised} /> :
                        <Flex justifyContent="space-around">
                            <Button size="sm" colorScheme="green" onClick={() => sendRequest('approve', data.id)}>Approve</Button>
                            <Button size="sm" colorScheme="red" onClick={() => sendRequest('reject', data.id)}>Reject</Button>
                        </Flex>
                    }
                </Td>
            }
        </Tr>
    );
};

export default KycItem;