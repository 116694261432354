import { AiOutlineCheck } from 'react-icons/ai'
import { ImCross } from 'react-icons/im'
import { Flex, Text } from '@chakra-ui/react'

const ActionText = ({ action }) => {
    return (
        <Flex alignItems="center" color={action === 'approve' ? 'green.400' : 'red.400'} justifyContent="space-evenly">
            {action === 'approve' ? <AiOutlineCheck /> : <ImCross />}
            <Text>{action === 'approve' ? 'Approved' : 'Rejected'}</Text>
        </Flex>
    );
}

export default ActionText;