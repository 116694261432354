import { useState } from 'react'
import { Stack, FormControl, Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react'
import { AiOutlineLogin } from 'react-icons/ai'

const FormContent = ({ onLogin }) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPasswordClick = () => setShowPassword(!showPassword);
    const isAdmissible = login.length >= 2 && password.length >= 2;

    return (
        <Stack
            spacing={4}
            p="1.5rem"
            backgroundColor="content"
            borderRadius="lg"
        >
            <FormControl>
                <Input
                    type="email"
                    placeholder="Login"
                    borderColor="primary"
                    color="primary"
                    value={login}
                    onInput={e => setLogin(e.target.value)}
                />
            </FormControl>
            <FormControl>
                <InputGroup>
                    <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        borderColor="primary"
                        color="primary"
                        value={password}
                        onInput={e => setPassword(e.target.value)}
                    />

                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" colorScheme="darkButton" color="content" onClick={handleShowPasswordClick}>
                            {showPassword ? "Hide" : "Show"}
                        </Button>
                    </InputRightElement>
                </InputGroup>
            </FormControl>

            <Button
                type="submit"
                colorScheme="darkButton"
                color="content"
                width="full"
                leftIcon={<AiOutlineLogin />}
                disabled={!isAdmissible}
                onClick={() => onLogin(login, password)}
            >
                Login
            </Button>
        </Stack>
    );
};

export default FormContent;