import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'

import { useAuthentication } from 'context/AuthProvider'
import {API_ENDPOINT, KYC_STATUS_DATA, TOAST_DEFAULT_STYLE} from 'config/constants'

const useAction = () => {
    const { accessToken } = useAuthentication();
    const toast = useToast();

    return useCallback((actionName, kycId) => {
        const statusData = KYC_STATUS_DATA[actionName];
        const url = API_ENDPOINT + '/' + statusData.uri;

        const headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        axios
            .post(url, { id: kycId }, { headers })
            .then(response => {
                if (response.data.success) {
                    toast({
                        title: statusData.toastTitle,
                        status: statusData.toastStatus,
                        ...TOAST_DEFAULT_STYLE
                    });
                }
            })
            .catch(err => {
                toast({
                    title: 'Request error',
                    description: err.response?.data?.message || 'Unknown error',
                    status: 'error',
                    ...TOAST_DEFAULT_STYLE
                });
            });
    }, [toast, accessToken]);
}

export default useAction;