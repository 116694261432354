const theme = {
    colors: {
        primary: '#211F24',
        content: '#FFEDDE',
        hoverContent: '#cfc3ba',

        darkButton: {
            500: '#211F24',
            600: '#403f42'
        },
        contentButton: {
            500: '#FFEDDE',
            600: '#cfc3ba'
        }
    },

    fonts: {
        heading: `'Poppins', sans-serif`,
        body: `'Poppins', sans-serif`,
    },

    styles: {
        global: {
            body: {
                bg: '#211F24'
            },
            '.chakra-portal .chakra-slide': {
                bg: '#211F24'
            }
        }
    }
};

export default theme;