import { Button, Flex, IconButton, Text } from '@chakra-ui/react'
import { FiMenu } from 'react-icons/fi'
import { useAuthentication } from 'context/AuthProvider'

const MobileNavigation = ({ onOpen, stats, ...rest }) => {
    const { handleLogout, decodedToken } = useAuthentication();

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            borderBottomWidth="2px"
            borderBottomColor="content"
            justifyContent="space-between"
            {...rest}
        >
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                color="content"
                borderColor="content"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Flex alignItems="center" gap={5} display={{ md: 'inherit', sm: 'none' }}>
                <Text color="content">Approved: {stats.approved}</Text>
                <Text color="content">Pending: {stats.pending}</Text>
                <Text color="content">Total: {stats.total}</Text>
            </Flex>

            <Flex alignItems="center" gap={5} display={{ md: 'inherit', sm: 'none' }}>
                <Text color="content">{decodedToken?.login}</Text>
                <Button colorScheme="red" onClick={() => handleLogout()}>Log out</Button>
            </Flex>
        </Flex>
    );
}

export default MobileNavigation;