import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import Theme from './theme'
import { AuthProvider } from 'context/AuthProvider'

import AuthenticatedRoute from './components/AuthenticatedRoute'
import BasicRoute from './components/BasicRoute'

import Kyc from './views/Kyc'
import Login from './views/Login'
import NotFound from './views/NotFound'

import '@fontsource/poppins/600.css'
const theme = extendTheme(Theme);

const App = () => {
    return (
        <ChakraProvider theme={theme}>
            <AuthProvider>
                <Routes>
                    <Route index element={<BasicRoute><Login /></BasicRoute>} />
                    <Route path="/kyc/pending" element={<AuthenticatedRoute><Kyc key="kyc-pending" status="pending" /></AuthenticatedRoute>} />
                    <Route path="/kyc/approved" element={<AuthenticatedRoute><Kyc key="kyc-approved" status="approved" /></AuthenticatedRoute>} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AuthProvider>
        </ChakraProvider>
    );
}

export default App;