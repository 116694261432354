import { Box, Flex, Image } from '@chakra-ui/react'
import { MdVerifiedUser } from 'react-icons/md'
import { BiUserX } from 'react-icons/bi'

import NavigationItem from './NavigationItem'

const LinkItems = [
    { name: 'Pending KYC', to: '/kyc/pending', icon: BiUserX },
    { name: 'Approved KYC', to: '/kyc/approved', icon: MdVerifiedUser }
];

const Sidebar = ({ isOpen, onClose, ...rest }) => {
    return (
        <Box
            transition="3s ease"
            borderRight={!isOpen ? '2px' : 'none'}
            borderRightColor="content"
            w={{ base: 'full', md: 60 }}
            pos={!isOpen ? 'fixed' : null}
            h="full"
            {...rest}
        >
            <Flex direction="column" rowGap={3}>
                <Image src={process.env.PUBLIC_URL + '/images/logo.png'} m={5} alt="W.A.G.T.I Logo" />
                {LinkItems.map(link => (
                    <NavigationItem key={link.name} icon={link.icon} to={link.to} onClick={onClose}>
                        {link.name}
                    </NavigationItem>
                ))}
            </Flex>
        </Box>
    );
}

export default Sidebar;